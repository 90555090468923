import React, { createContext, useState, useEffect } from 'react';
import * as signalR from '@microsoft/signalr';

const SignalRContext = createContext(null);

export const SignalRProvider = ({ children }) => {
    const [connection, setConnection] = useState(null);
    const [isConnected, setIsConnected] = useState(false); // Track the connection state

    useEffect(() => {
        const connect = async () => {
            const newConnection = new signalR.HubConnectionBuilder()
                .withUrl(process.env.REACT_APP_API_URL + "/gamehub")
                .withAutomaticReconnect()
                .build();

            try {
                await newConnection.start();
                console.log("SignalR connected");
                setConnection(newConnection);
                setIsConnected(true);
            } catch (err) {
                console.error("SignalR connection failed:", err);
                setIsConnected(false);
            }
        };

        connect().then(r => console.log("SignalR connection established"));

        return () => {
            if (connection) {
                connection.stop();
            }
        };
    }, []);

    return (
        <SignalRContext.Provider value={{connection, isConnected}}>
            {children}
        </SignalRContext.Provider>
    );
};

export const useSignalR = () => {
    const context = React.useContext(SignalRContext);
    if (context === null) {
        throw new Error("useSignalR must be used within a SignalRProvider");
    }
    return context;
};
